html,
body,
#root {
  height: 100%;
}

svg:not(:root) {
  overflow: hidden;
}

.SnackbarItem-variantInfo {
  background-color: #1f2d44 !important;
}
